/* Welcome to Compass. Use this file to define print styles.
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/print.css" media="print" rel="stylesheet" type="text/css" /> */

.noscroll {
	.outer-wrapper {
		display: none;
	}
	.overlay-wrapper {
		.close-button {
			display: none;
		}
		.recipe {
			.copy {
				span {
					display: block;
				}
			}
			.recipe-action {
				display: none;
			}
		}
	}
}

.recipe-wrapper {
	.recipe-action {
		display: none;
	}

	.copy span {
		display: block;
	}

	iframe {
		display: none;
	}

	img.do-print {
		display: block;
	}

	.section.related {
		display: none;
	}

	.recipe-title {
		display: none;
	}

	.section {
		width: 60%;
	}
}

body.recipe-video {
	.recipe-wrapper {
		margin-left: 0 !important;
	}
}
